<template>
	<div class="card">
		<a
			v-ga="$ga.commands.trackClick.bind(this, 'card', anchor)"
			:href="'#' + anchor"
			class="content"
		>
			<h3 :id="anchor + '-title'" class="title" v-html="content.card.title" />
			<p class="text" v-html="content.card.text" />
			<div class="icon">
				<font-awesome-icon
					:icon="['fal', content.icon]"
					size="3x"
					fixed-width
				/>
			</div>
		</a>
	</div>
</template>

<script>
export default {
	props: {
		content: Object,
		anchor: String
	}
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

.card {
	position: relative;
	z-index: 1;
	padding: 0 1em;
	margin-top: -2em;
	margin-bottom: 3em;
	flex: 25%;
	.title {
		margin: 0;
	}
	.content {
		position: relative;
		display: block;
		padding: 1em;
		background: $white;
		height: 100%;
		box-shadow: 0 2px 4px rgba($black, 0.25);
		border-radius: 0.25em;
		&:hover {
			.icon {
				transform: rotate(5deg);
				bottom: 1.25em;
				color: $blue;
				opacity: 0.5;
			}
		}
	}
	.icon {
		color: $black;
		position: absolute;
		right: 1em;
		bottom: 1em;
		opacity: 0.25;
		transition: 0.2s ease-in-out;
	}
}

@media (prefers-color-scheme: dark) {
	.card {
		.content {
			background: darken($black, 2%);
			box-shadow: 0 1px 2px rgba($black, 0.25);
			&:hover {
				.icon {
					color: $orange;
				}
			}
		}
		.icon {
			color: $white;
		}
	}
}
</style>
