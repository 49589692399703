<template>
	<article id="article" class="article">
		<Header />

		<section class="row" aria-label="Job titles">
			<card-item
				v-for="(card, key) in $t('pages[0].topics')"
				:key="key"
				:content="$t(card)"
				:anchor="key"
				:id="`section-${key}`"
				:aria-labelledby="key + '-title'"
			/>
		</section>

		<section
			v-for="({ title, text, icon }, key, index) in $t('pages[0].topics')"
			:key="key"
			:aria-labelledby="`section-${key}`"
			:id="key"
			class="section"
			tabindex="0"
		>
			<h2 :id="`section-${key}`" v-if="index === 0">
				<font-awesome-icon :icon="['fal', icon]" size="lg" fixed-width />
				<span v-html="title" />
			</h2>
			<h3 :id="`section-${key}`" v-else>
				<font-awesome-icon :icon="['fal', icon]" size="lg" fixed-width />
				<span v-html="title" />
			</h3>

			<p
				v-for="paragraph in $t(text)"
				:key="paragraph.key"
				v-html="paragraph"
			/>
		</section>
	</article>
</template>

<script>
import pages from '@/mixins/pages'
import card_item from '@/components/card_item.vue'

export default {
	mixins: [pages],
	components: {
		'card-item': card_item
	}
}
</script>
